import { CreationModeEnum, CreationModeEnumOrUsecaseEnum, InnerUploadFile, ThreeStage } from '@/types'
import { ReactNode, Dispatch, SetStateAction } from 'react'

interface ResponsiveCopy {
  full: string
  short: string
}

export const placeholderMap: Record<CreationModeEnumOrUsecaseEnum, ResponsiveCopy> = {
  [CreationModeEnum.CreateImg]: {
    full: 'Describe the image you want to generate',
    short: 'Describe your image ',
  },
  [CreationModeEnum.Create]: {
    full: 'Describe the video you want to generate',
    short: 'Describe your video ',
  },
  [CreationModeEnum.CreateHD]: {
    full: 'Describe the video you want to generate',
    short: 'Describe your video ',
  },
  [CreationModeEnum.Animate]: {
    full: 'Describe the video you want to generate',
    short: 'Describe your video',
  },
  [CreationModeEnum.AnimateHD]: {
    full: 'Describe the video you want to generate',
    short: 'Describe your video',
  },
  [CreationModeEnum.Repaint]: {
    full: 'Describe the target object you want to generate',
    short: 'Describe your video',
  },
  [CreationModeEnum.Extend]: {
    full: 'Describe the final look of the extended video',
    short: 'Describe your video',
  },
  [CreationModeEnum.Upscale]: {
    full: 'Describe the video you uploaded',
    short: 'Describe your video',
  },
}

export interface CreationInputControlsProps {
  uploading: boolean
  setUploading: Dispatch<SetStateAction<boolean>>
  expanded: boolean
  keyframeFiles: ThreeStage<InnerUploadFile | null>
  setKeyframeFiles: Dispatch<SetStateAction<ThreeStage<InnerUploadFile | null>>>
  fileId: string
  file: InnerUploadFile | null
  setFile: Dispatch<SetStateAction<InnerUploadFile | null>>
  creation: any
  onSamChange: (data?: any) => void
  onDeleteFile: () => void
  textarea?: ReactNode
  keyframesUploading: ThreeStage<boolean>
  setKeyframesUploading: Dispatch<SetStateAction<ThreeStage<boolean>>>
  enableAfc?: boolean
  setEnableAfc?: Dispatch<SetStateAction<boolean>>
  beforeUpload?: (file: File) => Promise<boolean>
  templateInputs?: Record<string, any>
  setTemplateInputs?: Dispatch<SetStateAction<Record<string, any>>>
}

export const MAX_LENGTH = 4000
