import { CreationInputControlsProps } from './common'
import { cls, getImageResolution } from '@/utils'
import GSUpload from '@/components/gs-upload'
// import Suggestion from './suggestion'
import KeyframeConditioning from '../keyframe-conditioning'
import { useCachedSwitches } from '@/hooks/useSwitches'
import IconPlus from '@haiper/icons-svg/icons/outline/plus-small.svg'

export default function I2vControls({
  expanded,
  keyframeFiles,
  setKeyframeFiles,
  keyframesUploading,
  setKeyframesUploading,
  enableAfc,
  setEnableAfc,
  setFile,
  uploading,
  setUploading,
  beforeUpload,
  file,
  textarea,
}: CreationInputControlsProps) {
  const { data: switches } = useCachedSwitches()
  const showKeyframeConditioning = !!switches?.keyframe_conditioning
  const showDefaultUpload = !showKeyframeConditioning

  return (
    <fieldset
      className={cls(
        'self-start flex flex-col gap-1 p-3 pt-0 bg-surface w-full shrink-0',
        expanded ? '' : 'hidden',
      )}
      aria-label='creation-input-fieldset'
    >
      {showDefaultUpload && (
        <div className='flex flex-col items-center gap-3'>
          <GSUpload
            file={file}
            fileType='image'
            uploading={uploading}
            setUploading={setUploading}
            onChange={setFile}
          />
        </div>
      )}
      {showKeyframeConditioning && (
        <>
          <KeyframeConditioning
            files={keyframeFiles}
            setFiles={setKeyframeFiles}
            uploading={keyframesUploading}
            setUploading={setKeyframesUploading}
            enableAfc={enableAfc}
            setEnableAfc={setEnableAfc}
            beforeUpload={beforeUpload}
            extra={
              <div className='w-full flex flex-col'>
                <div className='w-full grid grid-cols-3 gap-5 md:gap-13 py-1'>
                  <div className='flex items-center justify-center'>
                    {enableAfc ? null : (
                      <IconPlus className='size-5 text-icon-subdued' />
                    )}
                  </div>
                  <div className='flex items-center justify-center'>
                    {enableAfc ? (
                      <IconPlus className='size-5 text-icon-subdued' />
                    ) : null}
                  </div>
                </div>
              </div>
            }
          />
          <div className='flex w-full flex-col'>{textarea}</div>
        </>
      )}
    </fieldset>
  )
}
