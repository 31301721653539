import { CreationInputControlsProps } from '../common'
import { cls } from '@/utils'
import { useMemo, useState } from 'react'
import useActiveTemplate from '@/hooks/useActiveTemplate'
import { TemplateDetail, TemplateInputWidget } from '@/types'
import IconPlus from '@haiper/icons-svg/icons/outline/plus-large.svg'
import TemplateWidget from './widget'
import Loading from '@/components/loading'

export default function TemplateControls({ expanded, templateInputs, setTemplateInputs }: CreationInputControlsProps) {
  const { data: template } = useActiveTemplate()

  const inputs: TemplateInputWidget[] = useMemo(() => {
    const visibleInputs = ((template as TemplateDetail)?.inputs ?? []).filter((item) => item.visible !== false)

    const hasSam = visibleInputs.some((item) => item.widget === 'sam')

    // if the template has sam, only show sam
    const validTypes = hasSam ? ['sam'] : ['image_input', 'video_input', 'sam', 'text_input']

    const validInputs = (visibleInputs ?? [])?.filter((item) => validTypes.includes(item.widget))
    return validInputs
  }, [template])

  const seperator = useMemo(
    () => (
      <div className='flex items-center justify-center p-4 shrink-0 last-of-type:hidden' aria-label='seperator'>
        <IconPlus className='size-5 text-icon-subdued shrink-0' />
      </div>
    ),
    [],
  )

  return (
    <fieldset
      className={cls(
        'self-start flex flex-col gap-1 p-3 pt-0 bg-surface w-full shrink-0 justify-center items-center',
        expanded ? '' : 'hidden',
      )}
      aria-label='creation-input-fieldset'
    >
      {inputs?.length ? (
        <div className='w-full h-full flex flex-col md:flex-row items-center justify-start md:justify-center overflow-y-auto'>
          {inputs.map((widget) => {
            return (
              <>
                <TemplateWidget
                  inputWidget={widget}
                  templateInputs={templateInputs!}
                  setTemplateInputs={setTemplateInputs!}
                />
                {seperator}
              </>
            )
          })}
        </div>
      ) : (
        <div className='h-[166px] w-full flex items-center justify-center'>
          <Loading className='h-6' />
        </div>
      )}
      {/* {showTextarea && <div className='flex w-full flex-col'>{textarea}</div>} */}
    </fieldset>
  )
}
