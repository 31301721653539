import { creationInputAtom, enableAfcAtom } from '@/atoms'
import { CreationModeEnum, ModelVersion, TemplateDetail, TemplateSettingWidget } from '@/types'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { useCachedSwitches } from './useSwitches'
import useActiveTemplate from './useActiveTemplate'
import { isProduction, isStage } from '@/utils'
import useModelVersion from './useModelVersion'

const useCreationSettingVisibility = () => {
  const { mode } = useAtomValue(creationInputAtom)
  const { data: switches } = useCachedSwitches()
  const { data: activeTemplate } = useActiveTemplate()
  const { version: modelVersion } = useModelVersion()

  const model2Active = modelVersion === ModelVersion.TWO

  const templateSettings: TemplateSettingWidget[] = useMemo(() => {
    return (activeTemplate as TemplateDetail)?.settings ?? []
  }, [activeTemplate])

  const showMotionLevel =
    [CreationModeEnum.Create, CreationModeEnum.Animate].includes(mode as any) ||
    templateSettings.some((widget) => widget.key === 'motion_level')

  const showDuration =
    [CreationModeEnum.Create, CreationModeEnum.Animate, CreationModeEnum.CreateHD, CreationModeEnum.AnimateHD].includes(
      mode as any,
    ) || templateSettings.some((widget) => widget.key === 'duration')

  const showResolution =
    ([
      CreationModeEnum.CreateImg,
      CreationModeEnum.Create,
      CreationModeEnum.Animate,
      CreationModeEnum.CreateHD,
      CreationModeEnum.AnimateHD,
    ].includes(mode as any) &&
      !model2Active) ||
    mode === CreationModeEnum.CreateImg ||
    templateSettings.some((widget) => widget.key === 'resolution')

  const showAspectRatio =
    CreationModeEnum.CreateHD === mode ||
    CreationModeEnum.CreateImg === mode ||
    templateSettings.some((widget) => widget.key === 'aspect_ratio')

  const showFakeAspectRatio =
    [CreationModeEnum.CreateHD, CreationModeEnum.CreateImg].includes(mode as any) && !showAspectRatio

  const showStyles =
    ([CreationModeEnum.Create, CreationModeEnum.CreateHD, CreationModeEnum.CreateImg].includes(mode as any) &&
      !model2Active) ||
    !!activeTemplate?.styles?.length

  const enableAfc = useAtomValue(enableAfcAtom)

  const showCameraMovement =
    !!switches?.camera_movement &&
    !model2Active &&
    ([CreationModeEnum.CreateHD].includes(mode as any) || (CreationModeEnum.AnimateHD === mode && !enableAfc))

  const showSeed = true

  const showMotionMode = CreationModeEnum.CreateHD === mode && model2Active

  const validKeys: string[] = useMemo(() => {
    return [
      showMotionLevel && 'motion_level',
      showDuration && 'duration',
      showResolution && 'resolution',
      showAspectRatio && 'aspect_ratio',
      showSeed && 'seed',
    ].filter(Boolean) as string[]
  }, [showMotionLevel, showDuration, showResolution, showAspectRatio, showSeed])

  const showFastGen = (!isProduction || isStage) && !model2Active
  const showTemplateClient =
    (!isProduction || isStage) &&
    !model2Active &&
    !!(activeTemplate as TemplateDetail)?.template_client_name &&
    !!switches?.template_client

  const showFakeDuration =
    model2Active && [CreationModeEnum.CreateHD, CreationModeEnum.AnimateHD].includes(mode as any) && !showDuration
  const showEnhancePrompt = model2Active
  const showAPG =
    (!isProduction || isStage) &&
    // model2Active &&
    [CreationModeEnum.CreateHD, CreationModeEnum.AnimateHD].includes(mode as any)

  return {
    showSeed,
    showMotionLevel,
    showDuration,
    showFakeDuration,
    showFakeAspectRatio,
    showResolution,
    showAspectRatio,
    showCameraMovement,
    showStyles,
    showFastGen,
    showEnhancePrompt,
    showTemplateClient,
    showMotionMode,
    showAPG,
    validKeys,
  }
}

export default useCreationSettingVisibility
